.outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .image {
    width: 182px;
    height: 149px;
    background: url("./no-content.png") no-repeat center center;
    background-size: 182px 149px;
    margin-bottom: 26px;
  }
  .text {
    font-size: 18px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.7);
  }
}
