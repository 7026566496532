@function hrpx($value) {
  @return 100vh * $value / 750;
}

.listView {
  position: relative;
  flex: 1 1 auto;
  min-height: 0;
  .empty {
    position: absolute;
    width: 100%;
    top: 25%;
    left: 0;
  }
  .slider {
    width: 100%;
    height: 100%;
    overflow: visible;
    padding-top: hrpx(20);
  }
  .wrapper {
    height: 100%;
    width: 1px;
    margin-left: calc(50% - 12.5vw);
    position: relative;
  }
}

.tabletWrapper {
  position: absolute;
  width: hrpx(300);
  height: 100%;
  transition: transform 1s, opacity 1s;
  opacity: 1;
  &.hidden {
    opacity: 0;
  }
}

.tablet {
  width: hrpx(300);
  height: calc(100% + #{hrpx(25)});
  border-radius: hrpx(23);
  z-index: 1;
  transition:
    background-color 1s,
    box-shadow 1s,
    left 1s,
    top 1s,
    width 1s,
    height 1s;
  background-color: transparent;
  &.focus {
    transition-delay: 0s;
    transition-duration: 2s;
    .appLogo {
      animation: appLogo ease-in-out 2s infinite;
    }
  }
  &.postDeactivate {
    transition-delay: 0.5s;
  }

  &.focus, &.prepareEnlarge, &.enlarge, &.postDeactivate {
    .appDivider {
      opacity: 0;
    }
  }
  &.focus, &.prepareEnlarge, &.postDeactivate {
    box-shadow: #00fff9 0 0 hrpx(20) 0;
    background-color: rgba(28,35,42,0.6);
  }
  &.enlarge {
    background-color: #182f43;
  }
}
.appBrief {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: hrpx(300);
}

.appLogo {
  background-repeat: no-repeat;
  background-position: center center;
  width: hrpx(230);
  height: hrpx(165);
  margin-top: hrpx(45);
  margin-bottom: hrpx(25);
  background-size: contain;
  &.invalid {
    filter: #{"grayscale(100%)"};
  }
}
.appDivider {
  height: 1px;
  background-color: rgba(255,255,255,0.2);
  width: hrpx(230);
  transition: opacity 0.5s;
}
.appName {
  text-align: center;
  font-size: hrpx(18);
  font-weight: 600;
  margin-top: hrpx(25);
  margin-bottom: hrpx(10);
  color: #fff;
}
.appDesc {
  text-align: center;
  width: hrpx(200);
  height: hrpx(100);
  margin-bottom: hrpx(10);
  overflow-y: auto;
  font-size: hrpx(12);
  color: #fff;
}
.appButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  > * {
    margin: 0 hrpx(6);
    font-size: hrpx(14);
    height: auto;
    padding: hrpx(4) hrpx(15);
  }
}
.btnArrow {
  transform: translateY(hrpx(2));
  display: inline-block;
  width: hrpx(10);
  height: hrpx(15);
  margin-right: hrpx(-3);
  background: url("./arrow.svg") no-repeat left bottom;
  background-size: 100% 100%;
  color: #ffffff;
  &.arrow1 {
    animation: btnArrow 1s ease-in-out 0s infinite;
  }
  &.arrow2 {
    animation: btnArrow 1s ease-in-out 0.25s infinite;
  }
  &.arrow3 {
    animation: btnArrow 1s ease-in-out 0.5s infinite;
  }
}

@keyframes btnArrow {
  0% { opacity: 0.5 }
  25% { opacity: 1 }
  50% { opacity: 0.5 }
  100% { opacity: 0.5 }
}

@keyframes appLogo {
  0% { transform: translateY(0) }
  50% { transform: translateY(-12px) }
  100% { transform: translateY(0) }
}

.pager {
  position: absolute;
  right: hrpx(40);
  bottom: hrpx(30);
  width: hrpx(95);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  >* {
    width: hrpx(40);
    height: hrpx(40);
    >* {
      font-size: hrpx(18);
    }
  }
}

.introPage {
  position: absolute;
  left: hrpx(260);
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity 0.5s ease 1s;
  background:  #27344b url("./intro-bg-text.svg") no-repeat center center;
  > iframe {
    width: 100%;
    height: 100%;
    border: 0 none;
  }
  &.preparing {
    opacity: 0;
    transition-delay: 0s;
  }
  &.activeOnly {
    transition-delay: 0s;
  }
}

.listView.activeOnly {
  .tablet {
    transition: none;
  }
}
