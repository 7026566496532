@use "~@maxtropy/central-commons-ui/dist/style/themes/dark/color" as color;

.header {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-color: #1f293d;
  padding: 0 40px 0 30px;
  font-size: 14px;
  .title {
    color: transparent;
    user-select: none;
    width: 210px;
    height: 30px;
    background: url("../../assets/image/site-logo.svg") no-repeat left center;
    background-size: contain;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  .logoutBtn:not(:hover):not(:active) {
    color: color.$text-color;
  }
}

@media only screen and (min-width: 2560px){
  html, body{
    font-size: 20px;
  }
  .header{
    height: 3.5rem;
    padding: 0 3rem 0 2.5rem;
    font-size: 1rem;
    .title{
      width: 20rem;
      height: 2.2rem;
    }
  }
}

@media only screen and (min-width: 3840px){
  html, body{
    font-size: 30px;
  }
}
