@import "~@maxtropy/antd-theme/antd-dark.css";
@import "~@maxtropy/central-commons-ui/dist/style/themes/dark/color";

body {
  margin: 0;
  font-family: 'Microsoft YaHei', 'PingFang SC', '黑体', '宋体', sans-serif;

  .ant-cascader-picker, .ant-select {
    background-color: #2F3D4F;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border: 1px solid $border-color-base;
  -webkit-text-fill-color: $text-color;
  box-shadow: inherit;
  caret-color: $text-color;
  transition: background-color 7200s ease-in-out 0s;
}
