@function wrpx($value) {
  @return 100vw * $value / 1440;
}

.cardView {
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 16px 0 16px wrpx(24);
}

.empty {
  position: absolute;
  left: 0;
  width: 100%;
  top: 38%;
}

.card {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: wrpx(300);
  height: wrpx(150);
  border: 1px solid rgba(255,255,255,0.1);
  background-color: rgba(32,34,41,0.8);
  margin: 0 wrpx(24) wrpx(40);
  border-radius: 10px;
  transition: background-color 0.5s ease-out, box-shadow 0.5s ease-out;
  &:hover {
    background-color:rgba(0,96,171,0.17);
    box-shadow:0 0 16px 0 #00fff9;
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 4px;
      right: 4px;
      top: 4px;
      bottom: 4px;
      background: url("./card-corner-nw.svg") no-repeat left top,
      url("./card-corner-ne.svg") no-repeat right top,
      url("./card-corner-sw.svg") no-repeat left bottom,
      url("./card-corner-se.svg") no-repeat right bottom;
    }
  }
  .content {
    flex: 11 11 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgba(255,255,255,0.2);
  }
  .avatar {
    width: wrpx(75);
    height: wrpx(75);
    margin-left: wrpx(18);
    margin-right: wrpx(14);
    background: no-repeat center center;
    background-size: contain;
  }
  .text {
    color: #ffffff;
    flex: 1;
    margin-right: wrpx(15);
    word-break: break-all;
  }
  .title {
    font-size: wrpx(14);
    font-weight: 600;
    margin-bottom: wrpx(9);
  }
  .desc {

  }
  .buttons {
    flex: 4 4 0;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    .btn {
      flex: 1 1 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: wrpx(12);
      color: #ffffff;
      cursor: pointer;
      &.invalid {
        color: rgba(255,255,255,0.5);
        cursor: not-allowed;
      }
    }
    .divider {
      width: 1px;
      height: wrpx(20);
      background-color: rgba(255,255,255,0.2);
    }
  }
  &.invalid {
    .avatar {
      filter: #{"grayscale(100%)"};
    }
    .text {
      color: rgba(255,255,255,0.5);
    }
  }
}
